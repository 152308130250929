<template>
  <LayoutCentered>
    <div v-html="compiledMarkdown" />
  </LayoutCentered>
</template>

<script>
import marked from "marked";
import eula from "@/tos/EULA.md";
import LayoutCentered from "@/components/common/LayoutCentered.vue";

export default {
  name: "EULA",
  components: {
    LayoutCentered,
  },
  computed: {
    compiledMarkdown() {
      return marked(eula);
    },
  },
};
</script>

<style lang="scss" scoped>
.tos {
  font-size: 10px;
}
</style>

